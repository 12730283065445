

.margin1{
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 0px;
}


.submitBtn2{
    padding: 5px 20px;
    width: fit-content;
    border-radius: 20px;
    background-color: #8224e3;
    height: 45px;
    align-self: baseline;
  }

  .subHeader{
    display: flex;
    justify-content: space-between;
    border-bottom: solid;
    border-bottom-width: thin;
    border-bottom-color: #dddddd;
    cursor: pointer;
  }

  
.subHeader h4, h5{
    color: gray;
  }
  
  .title{
    line-height: 20px;
  }

  .resize{
    width: 5px;
  }